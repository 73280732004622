import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  MDBBox,
  MDBBtn,
  MDBBtnGroup,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBInput,
} from 'mdbreact';
import WebGlApp from 'webgl';
import { useLogout } from 'hooks/useLogout';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { setCompanyUsers, setProjects,setUser } from 'actions/user';
import { setJWT, tokenRef } from 'api';
import Loading from 'components/ui-components/loading';
import NoMeshModal from '../../components/NoMeshModal';
import addNotification from 'helpers/notify';
import { useHttp } from '../../hooks/useHttp';
import { getJsonFile  } from 'api/files/getJsonFile';
import { url,IFC_Url,urlWS } from 'api';






import { Maybe } from 'helpers/maybeFunctor';
import { getProjects } from 'api/projects/getProjects';
import { useHttpWithCache } from 'hooks/useHtthWithCache';
import {
  findRecursiveInTreeListJsonId,
  findRecursiveInTree,
  UpdateGroupVisibility,
  flattenTree,
  generateSubprojectHashmap,
  generateSubprojectMeshesHashed,
  findRecursiveInTreeJsonIdHashmap,
} from 'helpers/three';
import './SocketViewer.scss';
import {eventTrack,googleAnalytics4} from '../../helpers/ga4Helper'
import HashMap from 'hashmap'

import LoadingAdmin from "components/ui-components/loading";
import ErrorAdmin from "components/ui-components/Error";
import { mainAdminSetCompanies } from "actions/main-admin";
import { mainAdminGetCompanies } from "api/users/mainAdminGetCompanies";

const SocketViewer = props => {
  const { projectId, wsSessionId, token, companyId } = props;
  const dispatch = useDispatch();
  const logOut = useLogout();
  const viewerRef = useRef();
  const WebGL = useRef();
  const history = useHistory();

  const [userId, setUserId] = useState(null);








  useEffect(()=> {
    
    try {
      if(token) {
        const userInfo = jwt_decode(token);
         // console.log("userInfo",userInfo);
        localStorage.setItem(tokenRef, token);
        setJWT(token);
        setUserId(userInfo.id);
        dispatch(setUser(userInfo));
      }
    } catch(err){
      console.log(err);
      addNotification(err.message, 'danger');
      history.push('/');
    }
  }, [token])


  const userRedux = useSelector((s) => s.user.userInfo);
  const projectsRedux = useSelector(s => s.user.projects);
  const usersRedux = useSelector(s => s.user.users);
  

  const companiesRedux = useSelector((s) => s.mainAdmin.company);
  const companiesProjectsRedux = useSelector((s) => s.mainAdmin.company_projects);
  const allUsersRedux = useSelector((s) => s.mainAdmin.all_users);


  function findProject()
  {
   
    var company = null;
    var project = null;

   if (companiesProjectsRedux && userRedux?.admin)
    {
      company =   Maybe.of(companiesProjectsRedux).map(companies => {
       
// console.log("Needed project != companyId", companies);
return  companies.find(company => 
   {
  // console.log("Needed project company", company);
   return      company.projects.find(project => 
   {
   
     
   if (project && project.sessions && project._id === projectId) 
   {
    //  console.log("Needed project Company", project);
    return    project;
 }
});
}
)}).value.projects.filter(project  => project._id === projectId)[0]




   }
   

  if (projectsRedux && !userRedux?.admin && project == null) {
    
      project =  Maybe.of(projectsRedux).map(projects => {
    
        console.log(">>>>>>>>>>>>>>>>> projectsRedux", projectsRedux)
        return   projects.filter(project => project._id == projectId)[0]}).value; 

    }


    if (project) {
      return project;
    }

    if (company) {
      return company;
    }


  }


  const project = findProject()



  function findSession()
  {
   
    var company = null;
    var project = null;

   if (companiesProjectsRedux && userRedux?.admin)
    {
      company =   Maybe.of(companiesProjectsRedux).map(companies => {
       
// console.log("userRedux.company != companyId", companies);
return  companies.find(company => 
   {
    if (company && company.projects && company._id === companyId) 
    {

        return company
    }
 
}
)}).value.projects.map(project => project.sessions).map(sessions =>
  sessions.find(session => {
  
    return session._id === wsSessionId;
  })
).find(arg => arg != null);

 
//console.log("company", company);


   }
   

  if (projectsRedux && !userRedux?.admin) {
    project =  Maybe.of(projectsRedux).map(projects => {
      return   projects.find(project => {
        if (project && project.sessions) {
          return      project.sessions.find(
          session =>  {
          if (session &&  session._id === wsSessionId) {
          //  console.log("session exits list proj =>", session);
           return  session;
          }}
        );
      }}
      )}).map(project => project.sessions).map(sessions =>
        sessions.find(session => {
        
          return session._id === wsSessionId;
        }),
      ).value; 
     
  

    }


    if (project) {
      return project;
    }

    if (company) {
      return company;
    }


  }

 
  const neededSession =  findSession() 
    
 

    useEffect(() => {
      isMountedRef.current = true;
      if(isMountedRef.current && project && neededSession && (usersRedux || allUsersRedux) && (projectsRedux || companiesProjectsRedux)  && !isGLOpend) {
     
        setGLOpend(true);
      //  dispatch(initSession(userInfo));
        initializeAPI();      
      }
      return () => (isMountedRef.current = false);
    }, [projectsRedux, companiesProjectsRedux, usersRedux,allUsersRedux, neededSession, project]);
  




     



  const isMountedRef = useRef(null);

  const [meshesArray, setMeshes] = useState(null);
  const [hoverMesh, setHoverMesh] = useState({});
  const [clickedChild, setClickedChild] = useState([]);
  const [clickedUnmatchedChild, setClickedUnmatchedChild] = useState([]);
  //
  const [clickedExistingChild, setClickedExistingChild] = useState([]);
  const [clickedDemolitionChild, setClickedDemolitionChild] = useState([]);
  //


  //
  const [percentsLoaded, setPercentsLoaded] = useState(0);
  const [allowToResize, setAllowToResize] = useState(false);
  const [loading, setLoading] = useState(true);
   const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);

   const [selectGroups, setSelectGroups] = useState([]);
  const [isGLOpend, setGLOpend] = useState(false);

  let [modelNumber, setModelNumber] = useState(0);
  let [modelsNames, setModelsNames] = useState([]);
  let [jsons, setJsons] = useState([]);
  let [models, setModels ] = useState([]);







//const [message, setMessage] = useState([]);
const message = useRef([]);
const [payload, setPayload] = useState(null);
const serverMessage = useRef([]);
const [ws, setWs] = useState(null);


////createdisctionary for data handling

const matched = useRef(new HashMap());
const unmatched = useRef(new HashMap());
const existing = useRef(new HashMap());
const demolition = useRef(new HashMap());
const meshes = useRef(new HashMap());
const unmatchedMeshes = useRef(new HashMap());
const existingMeshes = useRef(new HashMap());
const demolitionMeshes = useRef(new HashMap());


const selectedMesh = useRef(null);






useEffect(() => {
 
  if (wsSessionId && (ws === null || (ws && ws.readyState !== 1 && ws.readyState !== 0 && ws.readyState !== 2)) ) {

  var  wss = new WebSocket(`${urlWS}/${wsSessionId}`)
    setWs(wss)
  }
 
      // history.push('/personal-area');
     
   
},  [ userId,wsSessionId]);









    // project files variables
 
    const { request: initializeAPI } =  useHttp({
      
      requestCallback: async ()   =>  await getJsonFile(neededSession.json).then(async res => {
        //console.log("initializeAPI",res);
        googleAnalytics4(userId)
          neededSession.zipInfo.pathsmapping.forEach(fname => {
           
            console.log("console.log(fname);",fname);
            if(fname.endsWith('.json') || fname.endsWith('.json.gz')) 
           {
             
             setModelNumber(modelNumber)
             modelNumber = modelNumber+1
            var fileName = fname.split('.')[0];
            
            modelsNames.push(fileName)
             setModelsNames(modelsNames)
           }
          });
          var baseURL = url;
              if (project?.isIfcProject) {
                baseURL = project.isIfcProject ? IFC_Url : url;
              }else if (neededSession?.isIfcProject)
              {
                baseURL = neededSession.isIfcProject ? IFC_Url : url;
              }
           
          
          neededSession.zipInfo.paths.forEach((path) => {
            if (path.endsWith(".glb") || path.endsWith(".glb.gz")) {
              models.push(`${baseURL}/${path}`);
              console.log("console.log(models);",models);
            } else if (path.endsWith(".json") || path.endsWith(".json.gz")) {
              jsons.push(`${path}`);
            }
          });
  
  
          setModels(models)
             setJsons(jsons)

  
          WebGL.current = new WebGlApp(
            viewerRef.current,
            {
              glbs: models         
            },
            {
              setClickedChild,
              setLoading,
              setError,
              setPercentsLoaded,
              setAllowToResize,
              setModal,
           //   setCoordsForPopover,
              findInJsonUsingViewer,
           //   setIsPopOver,
              onHoverMesh,
              
              
              
            selectedMesh,
            project,
            setPayload,
            },
            null,
            true,
            (project?.isIfcProject? project?.isIfcProject :(neededSession?.isIfcProject? neededSession?.isIfcProject : false))
          );        
          return res;
        }),
      onLoad: async res => {

       // console.log("res",res)
  
     matched.current = await generateSubprojectHashmap(res.$id,res.SubProjects)
     unmatched.current = await generateSubprojectHashmap(res.$id,res.UnmatchedSubProjects)
     existing.current = await generateSubprojectHashmap(res.$id,res.ExistingSubProjects)
     demolition.current = await generateSubprojectHashmap(res.$id,res.DemolitionSubprojects)
    
    
         meshes.current = await generateSubprojectMeshesHashed(res.SubProjects);
        unmatchedMeshes.current  = await generateSubprojectMeshesHashed(res.UnmatchedSubProjects);
        existingMeshes.current  = await generateSubprojectMeshesHashed(res.ExistingSubProjects);
        demolitionMeshes.current  = await generateSubprojectMeshesHashed(res.DemolitionSubprojects);

        {eventTrack("SocketView-Action","Socket3dView-opened",history.location.pathname,userId,false,wsSessionId)}
        // console.log('initializeAPI loaded')
      },
      onError: e => {
        console.error(e);
        setError(true);
      },
    }, );


    const {  loadingAdmin, errorAdmin, request :cacheRequestAdmin   } = useHttpWithCache({
      requestCallback: () => mainAdminGetCompanies(),
      reduxSetter: (data) => {
      //  console.log("cacheRequestAdmin",data);
        dispatch(mainAdminSetCompanies(data.company));
        dispatch({
          type: "MAIN_ADMIN_SET_ALL_COMPANIES_PROJECTS",
          payload: data.company_projects,
        });
        dispatch({
          type: "MAIN_ADMIN_SET_ALL_USERS",
          payload: data.all_users,
        });
      },
      reduxReset: () => {
        dispatch(mainAdminSetCompanies(null));
        dispatch({
          type: "MAIN_ADMIN_SET_ALL_COMPANIES_PROJECTS",
          payload: null,
        });
        dispatch({
          type: "MAIN_ADMIN_SET_ALL_USERS",
          payload: null,
        });
      },
      reduxCash: {
        company: companiesRedux,
        company_projects: companiesProjectsRedux,
        all_users: allUsersRedux,
      },
      allowToCash: true,
    });

  const {error: cacheError, loading: cacheLoading, errorStatus: cacheErrorStatus, request: cacheRequest, refresh } = useHttpWithCache({
    requestCallback: () =>  getProjects(),
    reduxSetter: data => {
    //  console.log('cacheRequest', data)
    dispatch(setProjects(data.projects));
    
     dispatch(setCompanyUsers(data.users));
    },
    reduxReset: () => {
      dispatch(setProjects(null));
      dispatch(setCompanyUsers(null));
    },
    reduxCash: {
      projects:  projectsRedux,
      users:  usersRedux,
    
    },
    allowToCash: true,
  });
  
  const onHoverMesh = id => {
  
    const neededMesh = meshes.current.get(id);
    setHoverMesh(neededMesh);
  };





 
  const pickMeshInNeededTree = (
    clicked,
    matched,
    setClickedChild,
    keyIsCtrl,
    selectors,
    treeIndex,
  ) => {

  //  console.log("picking mesh in needed tree ", clicked)
    //setSelectGroups([]);
    if (!clicked) {
      setModal(true);
    } else { 
      let newClicked

      if (clicked.length > 1) {
        clicked.forEach(m => {
          if (m) {
            newClicked = m
          //  console.log('File list => item within children of clicked', m )
          }
        });
      }

      let path
      let fullPath
      if (clicked.length > 1) 
      {
        
        fullPath = findRecursiveInTreeListJsonId(matched, newClicked.$id)
         path =   fullPath.path
        // console.log('treeList > json path from pick needed mesh ', treeMeshes, 'clicked.id = ',newClicked.$id )
           }else
           {
            fullPath   = findRecursiveInTreeJsonIdHashmap(matched, clicked.$id)
           // path   = fullPath.path
         //   console.log('tree > json path from pick needed mesh ', treeMeshes, 'clicked.id = ',clicked.$id )
           }
            
          
   
       if (clicked.length > 1) {
         clicked = newClicked
       }
    // console.log(fullPath,clicked)
    message.current = KeepSelected.current;

    let indexStored = message.current.indexOf(treeIndex);
    selectedMesh.current = clicked.UniqueId;
    let action = 'select'
    let selectionLog = WebGL.current.viewer.cachedSelection.get(`${treeIndex}`);


    if(indexStored === -1 && selectionLog !== undefined )
    {
      message.current.splice(0, 1,treeIndex);

     // let cachedSelection = this.cachedSelection.get(`${treeIndex}`)
   

     selectionLog.forEach((id) =>         {
          if (!message.current.includes(id)) {
            message.current.push(id)
          } 

        }) 


    }
    if (keyIsCtrl) {
      
      if (indexStored !== -1) {
          
        let itemStored = message.current.indexOf(fullPath.result.id);
       if (itemStored !== -1) {
        message.current.splice(itemStored, 1);
          action = 'deselect'
          
        }else{
       //   message.current.splice(++indexStored, 0, fullPath.result.id);
       
            message.current=  [...message.current.slice(0, indexStored), ...message.current.slice(indexStored+1,message.current.length)]
         
         
        }
        
       
     
      }else
      {
        console.log({fullPath})
        message.current.push(treeIndex,fullPath.result.id)
     
        
      }
 //     setPayload(['ctrlclick',JSON.stringify(message.current)])
    

    }
    else
    {

      message.current= []
      message.current.push(treeIndex,fullPath.result.id)
 
      
     //  setPayload(['click',JSON.stringify(message.current)])

    }
   
      

      // setClickedChild(s => {
      //   if (keyIsCtrl) {
      //     if (s.find(mesh => mesh.UniqueId === clicked.UniqueId)) {
      //       const neededIndex = s.findIndex(
      //         mesh => mesh.UniqueId === clicked.UniqueId,
      //       );
      //       return [...s.slice(0, neededIndex), ...s.slice(neededIndex + 1)];
      //     }
      //     return [...s, clicked];
      //   } else {
      //     return [clicked];
      //   }
      // });
     
     
      // setScrollId(fullPath.result.id);
     
      WebGL.current.viewer.onClickChildInListSocket(
        clicked? clicked.UniqueId : '',
        keyIsCtrl,
        selectors,
        action,
        true,
        fullPath.result.id,
      );

      
    }
  };

 

 
  const findInJsonUsingViewer = (id, keyIsCtrl) => {
    const clickedUsualMesh = meshes.current.get(id);
    const clickedUnpricedMesh = unmatchedMeshes.current.get(id);
    //
    const clickedExistingMesh = existingMeshes.current.get(id);
      //
      const clickedDemolitionMesh = demolitionMeshes.current.get(id);

    if (clickedUnpricedMesh) {
      
      pickMeshInNeededTree(
        clickedUnpricedMesh,
        unmatched,
        setClickedUnmatchedChild,
        keyIsCtrl,
        {
          prevMeshes: 'prevUnmatchedMeshes',
          prevMaterials: 'prevUnmatchedMaterials',
          color: 'red',
          treeIndex: '1',
       
        },
        '1',
      );
    } else if (clickedExistingMesh) {
      
      pickMeshInNeededTree(
        clickedExistingMesh,
        existing,
        setClickedExistingChild,
        keyIsCtrl,
        {
          prevMeshes: 'prevExistingMeshes',
          prevMaterials: 'prevExistingMaterials',
          color: 'purple',
          treeIndex: '2',
         
        },
        '2',
      );
    }  else if  (clickedUsualMesh){
      
      pickMeshInNeededTree(
        clickedUsualMesh,
        matched,
        setClickedChild,
        keyIsCtrl,
        {
          prevMeshes: 'prevMeshes',
          prevMaterials: 'prevMaterials',
          color: 'blue',
          treeIndex: '0',
        
        },
        '0',
      );
    }else if  (clickedDemolitionMesh){
      

      pickMeshInNeededTree(
        clickedDemolitionMesh,
        demolition,
        setClickedDemolitionChild,
        keyIsCtrl,
        {
          prevMeshes: 'prevDemolitionMeshes',
          prevMaterials: 'prevDemolitionMaterials',
          color: 'brown',
          treeIndex: '3',
        
        },
        '3',
      );
    }

  };



  useEffect(() => {
      isMountedRef.current = true;
      if ((!usersRedux && !allUsersRedux) && (!projectsRedux && !companiesProjectsRedux ) && isMountedRef.current ) {
          // history.push('/personal-area');
          if (userRedux?.admin) {
            cacheRequestAdmin();
          }else
          {
            cacheRequest();
          }
          
      } else {
        //   initializeAPI();
      }
      return () => (isMountedRef.current = false);
    }, [projectsRedux, companiesProjectsRedux , usersRedux, allUsersRedux, userRedux]);
  


  useEffect(() => {
    if(cacheErrorStatus) {
      const stringError = String(cacheErrorStatus);
      addNotification(stringError, 'danger');
      if(stringError.toLowerCase()?.includes('unexpected token')) {
        logOut();
        history.push('/');
      }
    }
  },[cacheErrorStatus])


 // let  list = []
  const  KeepSelected  = useRef([]);
  useMemo(() => {
    if (ws ) { 
      ws.onmessage = function (message) {
        let data = JSON.parse(message.data)
        console.log('>>data happened >>',data )
        if (data) {
          let arr = data.body.length?JSON.parse(data.body):[]
          let i =0
          let selectors = {}
          let treeIndex = 0  
          KeepSelected.current = [];
          // arr.map(item => {
          //   KeepSelected.current.push(item)
          // })
          KeepSelected.current = arr.flatMap(item => item);

          console.log('>>KeepSelected.current sliced >>',KeepSelected.current.slice(1,KeepSelected.current.length) )

          WebGL.current.viewer.cachedSelection.set(`${KeepSelected.current[0]}`, KeepSelected.current.length > 1?KeepSelected.current.slice(1,KeepSelected.current.length):[])
           console.log('>>arr >>',arr )
           console.log('>>WebGL.current.viewer.cachedSelection >>',WebGL.current.viewer.cachedSelection )
          // message.current = arr.flatMap(item => item)
         
          console.log('>>message.current >>',KeepSelected.current )
        
          let  selectedTree = null
        //  let  selectedTreeMeshes = null
          switch (arr[0]) {
            case '0':
              selectors={
                prevMeshes: 'prevMeshes',
                prevMaterials: 'prevMaterials',
                color: 'blue',
                treeIndex: 0,
              }
              treeIndex =0;
              selectedTree = matched;
            //  selectedTreeMeshes = meshes;
              break;

              case '1':
                selectors={
                  prevMeshes: 'prevUnmatchedMeshes',
                  prevMaterials: 'prevUnmatchedMaterials',
                  color: 'red',
                  treeIndex: 1,
                }
                treeIndex =1
                selectedTree = unmatched;
              //  selectedTreeMeshes = unmatchedMeshes;
                break;

                case '2':
                  selectors={
                    prevMeshes: 'prevExistingMeshes',
                    prevMaterials: 'prevExistingMaterials',
                    color: 'purple',
                    treeIndex: 2,
                  }
                  treeIndex =2
                  selectedTree = existing;
                //  selectedTreeMeshes = existingMeshes;
                  break;
                  case '3':
                    selectors={
                      prevMeshes: 'prevDemolitionMeshes',
                      prevMaterials: 'prevDemolitionMaterials',
                      color: 'brown',
                      treeIndex: 3,
                    }
                    treeIndex =3
                    selectedTree = demolition;
                //    selectedTreeMeshes = demolitionMeshes;
                    break;
          
            default:
              break;
          } 
          switch (data.header) {
          
          
            case 'session':
              
            switch (data.state) {
              case 'create':
                break;
        
                case 'join':
        
        
                break;
        
        
                case 'leave':
                break;
            
            
              default:
                break;
            }
              
              break;
        
        

              case 'action':
              
                switch (data.state) {
                  case 'click':
                    console.log("click ");
                   
                   
                   {
                    if (arr.length === 1) {
                         
                      console.log('resetting after click ')
                      resetSelection (selectedTree, selectors)
                   }
                    arr.forEach(jsonID => {
                      
                      
                          
                   

                      if (i !==0)
                       { 
                       
                        
                        if (i > 1) {
                          selectClick(selectedTree, jsonID,selectors, true)
                        }else if (i < 2 && arr.length !== 1)
                        {
                          selectClick(selectedTree, jsonID,selectors, false)
                        }
                       
                        
                      
                      
                     
                     
                      }
                      i++;
                     
                    })
                  //  WebGL.current.viewer.targetItems()
                  }

                  
                  
                    break;
                    case 'deselect':
                      console.log("deselect ");
                    //  KeepSelected.current = [];
                     {
    
                      

                      arr.forEach(jsonID => {

  
                        if ( i !==0)
                         {  
                         
                         

                   
                            deselectClick(selectedTree, jsonID,selectors)
                        
                        }
                         i++;
                        }
                      
                      
                      )
                     
                    }
  
                    
                    
                      break;
            
                    case 'ctrlclick':
                      console.log("ctrlclick ");
                    
                
                      arr.forEach(jsonID => {         
                        if ( i !==0)
                        {  
                         
                          selectClick(selectedTree, jsonID,selectors,true)
                      
                        }
                        
                        i++;
                      })
                     
                    //  WebGL.current.viewer.targetItems()
                

                    break;
                    case 'move':
                    
                      let treeAIndex = parseInt(arr[0]) ; 
                      let treeBIndex = parseInt(arr[1]) ; 
                      arr.forEach(jsonID => {
                        if (treeAIndex === 0 && treeBIndex === 1 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(matched,meshes,unmatched,unmatchedMeshes, jsonID)
                        }
                         if (treeAIndex === 0 && treeBIndex === 2 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(matched,meshes,existing,existingMeshes, jsonID)}
                         if (treeAIndex === 0 && treeBIndex === 3 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(matched,meshes,demolition,demolitionMeshes, jsonID)}


                         if (treeAIndex === 1 && treeBIndex === 0 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(unmatched,unmatchedMeshes, matched,meshes, jsonID)}
                         if (treeAIndex === 1 && treeBIndex === 2 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(unmatched,unmatchedMeshes,existing,existingMeshes, jsonID)}
                         if (treeAIndex === 1 && treeBIndex === 3 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(unmatched,unmatchedMeshes,demolition, demolitionMeshes, jsonID)}



                         if (treeAIndex === 2 && treeBIndex === 0 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(existing,existingMeshes, matched,meshes, jsonID)}
                         if (treeAIndex === 2 && treeBIndex === 1 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(existing,existingMeshes,unmatched,unmatchedMeshes, jsonID)}
                         if (treeAIndex === 2 && treeBIndex === 3 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(existing,existingMeshes,demolition,demolitionMeshes, jsonID)}



                         if (treeAIndex === 3 && treeBIndex === 0 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(demolition, demolitionMeshes, matched,meshes, jsonID)}
                         if (treeAIndex === 3 && treeBIndex === 1 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(demolition,demolitionMeshes, unmatched,unmatchedMeshes. jsonID)}
                         if (treeAIndex === 3 && treeBIndex === 2 && i !==0 && i!==1)
                         { moveFromTreeAtoTreeB(demolition,demolitionMeshes,existing,existingMeshes, jsonID)}

                        
                    


                        i++;
                       
                      })

    
                      selectors={
                        prevMeshes: 'prevMeshes',
                        prevMaterials: 'prevMaterials',
                        color: 'blue',
                        treeIndex: 0,
                      }
                       resetSelection (matched, selectors)
                       selectors={
                         prevMeshes: 'prevUnmatchedMeshes',
                         prevMaterials: 'prevUnmatchedMaterials',
                         color: 'red',
                         treeIndex: 1,
                       }
                       resetSelection (unmatched, selectors)
                       selectors={
                         prevMeshes: 'prevExistingMeshes',
                         prevMaterials: 'prevExistingMaterials',
                         color: 'purple',
                         treeIndex: 2,
                       }
                       resetSelection (existing, selectors)
                       selectors={
                         prevMeshes: 'prevDemolitionMeshes',
                         prevMaterials: 'prevDemolitionMaterials',
                         color: 'brown',
                         treeIndex: 3,
                       }
                       resetSelection (demolition, selectors)

                     

                    break;
          
                    case 'hide':
                      let visible = (arr[1] === 'True'); 
                      arr.forEach(jsonID => {
                        if (treeIndex === 0 && i !==0 && i !==1)
                        {hide(matched, jsonID,visible)}
                        if (treeIndex === 1 && i !==0 && i !==1)
                        {hide(unmatched, jsonID,visible)}
                        if (treeIndex === 2 && i !==0 && i !==1)
                        {hide(existing, jsonID,visible)}
                        if (treeIndex === 3 && i !==0 && i !==1)
                        {hide(demolition, jsonID,visible)}
                        i++;
                       
                      })
                    
                     
                  
                    break;
        
                    case 'reset':
                     
                      console.log("reset was clicked:  ",data.state);
                  //    KeepSelected.current = [];
                   selectors={
                    prevMeshes: 'prevMeshes',
                    prevMaterials: 'prevMaterials',
                    color: 'blue',
                    treeIndex: 0,
                  }
                   resetSelection (matched, selectors)
                    selectors={
                      prevMeshes: 'prevUnmatchedMeshes',
                      prevMaterials: 'prevUnmatchedMaterials',
                      color: 'red',
                       treeIndex: 1,
                    }
                    resetSelection (unmatched, selectors)
                    
                    
                    resetMatchedToUnmatched(matched, unmatched)
                    
                      
                    break;
                
                
                  default:
                    break;
                }
                  
                  break;
          
            default:
              console.log("Unvalid message! Due to unrecognized header:  ",data.header);
              break;
          }
        }
       
      }

      ws.onclose = function (message) {
        ws.isAlive = false;
        ws.close();
      }

      ws.onerror = function (message) {  
        console.log('Web Socket error',message)
      //  ws.close();
      }
      
    }

 },  [ ws,matched,meshes, unmatched,unmatchedMeshes,existing,existingMeshes,demolition,demolitionMeshes ]); 


//  meshes.current = await generateSubprojectMeshesHashed(res.SubProjects);
//  unmatchedMeshes.current  = await generateSubprojectMeshesHashed(res.UnmatchedSubProjects);
//  existingMeshes.current  = await generateSubprojectMeshesHashed(res.ExistingSubProjects);
//  demolitionMeshes.current  = await generateSubprojectMeshesHashed(res.DemolitionSubprojects);




function moveFromTreeAtoTreeB(treeA,meshesA, treeB,meshesB, jsonID)
{

  console.log("treeA obejct",{treeA})
  console.log("treeB obejct",{treeB})
  let groupExist = false;
  let subExist = false;
  let oldgroup = findRecursiveInTreeJsonIdHashmap(treeA,jsonID).parent;
  let oldsub = findRecursiveInTreeJsonIdHashmap(treeA,oldgroup.id).parent;

  let newgroup = null;
  let newsub = null;

  const tt = treeA.current.get(jsonID);
  let newTreeItem =  JSON.parse(JSON.stringify(tt));
  
  console.log('before change',{newTreeItem})

  // newTreeItem.current.set(tt.id, {
  //   id: tt.id,
  //   parentId: tt.parentId,
  //   treeId: tt.treeId,
  //   key: tt.key,
  //   treeVisible: true,
  //   treeSelected:false,
  //   label: tt.label,
  //   meshInfo: tt.meshInfo,
  //   nodes: [],
  // }) 

  console.log("creating obejct",{newTreeItem})

  treeB.current.forEach(sub => {
    if (sub.label == oldsub.label) {
      console.log("sub exist ")
      newsub = sub;
      subExist =true;
    sub.nodes.forEach(group => {
      if (group.label == oldgroup.label) {
         console.log("group exist ")
        newTreeItem.parentId = group.id
        newTreeItem.treeId = group.treeId
        newgroup = group;
        groupExist = true;

        newTreeItem = {
          id: tt.id,
          parentId: group.id,
          treeId: group.treeId,
          key: tt.key,
          treeVisible: true,
          treeSelected:false,
          label: tt.label,
          meshInfo: tt.meshInfo,
          nodes: [],
        }
      }
    })
  }
 
    
  });

  if (!subExist) {

    console.log("sub dosen't exist ")
    console.log("tree id of treeB", treeB.current.values())
      treeB.current.set(oldsub.id,{ 
        id: oldsub.id,
        parentId: oldsub.treeId,
        treeId: oldsub.treeId,
        treeVisible: true,
        treeSelected:false,
        key: oldsub.id,
        label: oldsub.label,
        nodes: []})
      }else {
        console.log("sub exist ")
        console.log("newsub.id", newsub.id)
        treeB.current.set(newsub.id,{ 
          id: newsub.id,
          parentId: newsub.treeId,
          treeId: newsub.treeId,
          treeVisible: true,
          treeSelected:false,
          key: newsub.id,
          label: newsub.label,
          nodes: newsub.nodes})
      }
    
      if (!groupExist && subExist) {
        console.log("group  dosen't exist but subExist  exist ")
        console.log("oldgroup.id", oldgroup.id)
        treeB.current.set(oldgroup.id,{ 
          id: oldgroup.id,
          parentId: newsub.id,
          treeId: newsub.treeId,
          treeVisible: true,
          treeSelected:false,
          key: oldgroup.id,
          label: oldgroup.label,
          nodes: []})


        }

        if (!groupExist && !subExist) {

          console.log("group  dosen't exist and sub dosent exist ")
          treeB.current.set(oldgroup.id,{ 
            id: oldgroup.id,
            parentId: oldsub.id,
            treeId: oldsub.treeId,
            treeVisible: true,
            treeSelected:false,
            key: oldgroup.id,
            label: oldgroup.label,
            nodes: []})
  
  
          }

          console.log("before set obejct",newTreeItem.current)

          
  // const newTreeItem = treeA.current.get(jsonID);
  // const hash = new HashMap()
  // hash.set(jsonID, newTreeItem)
 
  // treeB.current.copy(hash)
  // treeA.current.delete(jsonID)

  console.log('after change',{newTreeItem})
  console.log("treeB.current before set obejct",treeB.current)
    treeB.current.set(newTreeItem.id,  newTreeItem)
    meshesB.current.set(newTreeItem.meshInfo.UniqueId, newTreeItem.meshInfo)
    console.log("treeB.current after set obejct",treeB.current)
    treeA.current.delete(jsonID)
    meshesA.current.delete(newTreeItem.meshInfo.UniqueId)
    
}


const mergeTree = (treeOne, treeTwo) => {
  const newTree = treeOne.current.clone();

  newTree.copy(treeTwo.current)
  return newTree
}

  function resetMatchedToUnmatched(treeData, treeUnmatchedData)
  {
 
 const mergedTree = mergeTree(treeData,treeUnmatchedData )
   treeData.current = new HashMap();
   treeUnmatchedData.current =  mergedTree;
  
  }




  function arrayRemove(arr, value) { 
    arr.splice(arr.findIndex(e => e.name === value),1);
  }



  function hide(tree, jsonID, visible){
   


      
       const newTreeItem = tree.current.get(jsonID);
          console.log("newTreeItem ",newTreeItem);
          if (!newTreeItem.meshInfo?.UniqueId) 
            return;

            console.log("newTreeItem ",newTreeItem);
    if (newTreeItem.treeVisible === true && visible === false ) 
    {
      newTreeItem.treeVisible = false;
      
        if (newTreeItem.treeVisible === false) {
          (function disable(tree) {
            tree.treeVisible = false;
            if (tree.meshInfo) {
              let temp = WebGL.current.viewer.meshesObject.get(
                tree.meshInfo.UniqueId,
              );
              if (temp) UpdateGroupVisibility(temp, false);
            }
  
           //  disable(item);
          })(newTreeItem);
        }
      
    } else { if (newTreeItem.treeVisible === false && visible === true ) 
       {
        newTreeItem.treeVisible = true;
        (function enable(tree) {
          tree.treeVisible = true;
          if (tree.meshInfo) {
            let temp = WebGL.current.viewer.meshesObject.get(
              tree.meshInfo.UniqueId,
            );
            if (temp) UpdateGroupVisibility(temp, true);
          }
          // enable(tree);
        })(newTreeItem);
      }
    }
  
  }
  
  
  
  function resetSelection (tree, selectors)  {
   

    tree.current.keys().forEach(jsonID => {
      const newTreeItem = tree.current.get(jsonID);

      if (newTreeItem) {
        {
          (function enable(tree) {
            if ( tree.meshInfo?.UniqueId)

              tree.treeSelected = false
          })(newTreeItem);

        
        }
      }
    });
  
    WebGL.current.viewer.onClickChildInListSocket(
      '',
      false,
      selectors,
      'reset',
      false,
      null,
     )
       
       
   
         selectedMesh.current = null;
     
   
     
     
      
      
   

    }
  

    function selectClick (tree, jsonID, selectors, isControl)  {

   
      const newTreeItem = tree.current.get(jsonID);
       if (!newTreeItem?.meshInfo?.UniqueId) 
         return;
       
       
   
         selectedMesh.current = newTreeItem.meshInfo?.UniqueId;
     
     
        {
           (function enable(tree) {
             if ( tree.meshInfo?.UniqueId)

               tree.treeSelected = true
           })(newTreeItem);
         }
      
   
         WebGL.current.viewer.onClickChildInListSocket(
          newTreeItem.meshInfo?.UniqueId,
          isControl,
          selectors,
          'select',
          false,
          jsonID,

         )
       
   
   
   
       
     
      }


        function deselectClick (tree, jsonID, selectors)  {

  
          const newTreeItem = tree.current.get(jsonID);
            if (!newTreeItem?.meshInfo?.UniqueId) 
              return;
            
            
        


            selectedMesh.current = null;
        
                           
          
            {
                (function enable(tree) {
                  if ( tree.meshInfo?.UniqueId)
        
                    tree.treeSelected = false
                })(newTreeItem);
              }
          
        
              WebGL.current.viewer.onClickChildInListSocket(
              newTreeItem.meshInfo?.UniqueId,
              false,
              selectors,
              'deselect',
              false,
              jsonID,
    
              )
         
        
        
        
            
          
          }


useMemo(() => {
  if (ws ) { //&& ws.readyState === 1 
  //  console.log("ws payload:  ",payload);
var message = JSON.stringify({
  roomid:`${wsSessionId}`,
  header: 'action',
  state: `${payload[0]}`,
  body: `${payload[1]}`,
 }, null, '\t')
    try {
    //  console.log("ws  ws.send(message):  ",message);
        ws.send(message) //send data to the server
    } catch (error) {
        console.log(error) // catch error
    }
  }


}, [payload])

  return (
    <MDBBox className="socketviewer d-flex overflow-hidden position-relative">
      <div
      className="resize-panel" 
     // style={{ width: window.innerWidth }}
      style={{ flexGrow: 1 }}
            
              ref={viewerRef}
            >
              {/* {isPopover ? (
                <MDBBox
                  className="position-absolute viewer-popover border border-dark"
                  style={popoverCoords}
                >
                  {hoverMeshInfo()}
                </MDBBox>
              ) : null} */}
              {loading ? (
                <MDBBox className="h-100 w-100 flex-center flex-column">
                  <Loading color="black" text="Loading model..." />
                  {percentsLoaded ? (
                    
                   //{jsonFilesMeshesToJSX},  {jsonUnmatchedMeshesToJSX},{jsonExistingMeshesToJSX},{jsonDemolitionMeshesToJSX}  ,
                    <span>{percentsLoaded}% has loaded...</span>
                  ) : null}
                </MDBBox>
              ) : null}
                <MDBBtnGroup
                  size="sm"
                  
                  className="position-absolute modal3d-tool-group-btn"
                >
                  <p id="clippingTooltip" />

                   <MDBBtn
                  size="sm"
                 // position="left"
                  color=""
                  className="bg-white modal3d-tool-btn "
                  onClick={() => {
                    setPayload(['refresh'])
                   // window.location.reload(false);
                    {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                    
                  }}>
                   <MDBIcon icon="refresh"  className="font-size-1" />
                   </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => WebGL.current.viewer.onZoom(0.1)}
                  >
                    <MDBIcon icon="plus" className="font-size-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() => WebGL.current.viewer.onZoom(-0.1)}
                  >
                    <MDBIcon icon="minus" className="font-size-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color=""
                    className="bg-white modal3d-tool-btn"
                    onClick={() =>
                      WebGL.current.viewer.onScreenShot()
                    }
                  >
                    <a id="take-snapshot">
                      <MDBIcon icon="camera" className="font-size-1" />
                      &nbsp; <b>[S] </b>
                    </a>
                  </MDBBtn>
                 
                  <MDBBtn
                  size="sm"
                  position="left"
                  color=""
                  className="bg-white modal3d-tool-btn"
                  onClick={() => {
                    WebGL.current.viewer.onInitialPosition()
                    {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                  }}
                >
                   <MDBIcon icon="home"  className="font-size-1" />
                   &nbsp; <b>[H] </b>
                </MDBBtn>
                
                  <MDBBtn

                  size="sm"
                //  position="left"
                
                  color=""
                  className="bg-white modal3d-tool-btn "
                  onClick={ () => {
                   // WebGL.current.viewer.targetItem( selectedMesh.current)
                    WebGL.current.viewer.targetItems()
                   // {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                  }}>
                   <MDBIcon icon="map-marker"  className="font-size-1" />
                   &nbsp; <b>[F] </b>
                   </MDBBtn>
                   <MDBBtn
                  size="sm"
                 // position="left"
                  color=""
                  className="bg-white modal3d-tool-btn"
                 	onClick={(e) => {
											WebGL.current.viewer.onShowGrid(e.currentTarget);
                    {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
                  }}>
                   <MDBIcon icon="braille"  className="font-size-1" />
                   &nbsp; <b>[G] </b>
                   </MDBBtn>
                   <MDBBtn
                    id="measurementToggleBtn"
                    title="Toggle Measurement Tool in viewer"
										size="sm"
										// position="left"
										color=""
										className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
											WebGL.current.viewer.toggleMeasurement(e.currentTarget);
											//   {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
										}}
									>
										<MDBIcon icon="tape" className="font-size-1" />
										&nbsp; <b>[M] </b>
                  </MDBBtn>
                  <MDBBtn
                    id="clippingToggleBtn"
                    title="Toggle Clipping Tool in viewer"
										size="sm"
										// position="left"
										color=""
										className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
											WebGL.current.viewer.toggleClipping(e.currentTarget);
											//   {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
										}}
									>
										<MDBIcon icon="cut" className="font-size-1" />
										&nbsp; <b>[C] </b>
									</MDBBtn>

                  <MDBBtn
                    id="isolateToggleBtn"
                    title="Toggle Isolation Tool in viewer"
										size="sm"
										// position="left"
										color=""
										className="bg-white modal3d-tool-btn"
                    onClick={(e) => {
											WebGL.current.viewer.toggleIsolate(e.currentTarget);
											//   {eventTrack("View-3DDesktop-Action","View-3DDesktop",{userId},'+1',false,'+1')}
										}}
									>
										<MDBIcon icon="box" className="font-size-1" />
										&nbsp; <b>[I] </b>
									</MDBBtn>
                </MDBBtnGroup>
             {/* </MDBBtnGroup> */}
            </div>
         
      <NoMeshModal isOpen={modal} toggle={() => setModal(s => !s)} />
      
    </MDBBox>
  );
};

export default SocketViewer;
