import {
  Box3,
  Matrix4,
  Vector3,
  Geometry,
  //Material,
  Mesh,
  BoxGeometry,
  Object3D,
  Euler,
  BufferGeometry,
  InstancedMesh,
  DynamicDrawUsage,
  Color,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshPhongMaterial,
  BufferAttribute,
  BufferGeometryLoader,
  Scene,
  Quaternion,
  InstancedBufferGeometry,
  DoubleSide,
  LOD
} from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { SetChildrenDepthWrite, modify } from '../../../helpers/three';
import { doubleToFloat } from '../../../helpers/doubleToFloat';
//examples/jsm/modifiers
//import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier';
import HashMap from 'hashmap'
import { forEach } from 'jszip';
//import { makeMerged } from 'instance.js'

let stats;

export default class Loader {
  constructor(viewer, glbs, ReactAPI, isIfcProject) {
    this.viewer = viewer;
    this.urlsModel = glbs;
    this.ReactAPI = ReactAPI;
    this.isIfcProject = isIfcProject
    var modelProps = {
      scale: new Vector3(0, 0, 0),
      position: new Vector3(0, 0, 0),
      rotation: new Euler(),
      mapMeshes: new HashMap(),
      meshes: [],

      created: false,
    }

    let instances = new HashMap();

    var userData;
    const scale = new Vector3();
    let totalglbs = 0
    this.urlsModel.glbs.forEach(async modelUrl => {

      totalglbs++
      await this.loadModel(modelProps, modelUrl, this.viewer, this.ReactAPI, this.isIfcProject);

    });
    this.viewer.meshesObject = modelProps.mapMeshes;
    this.viewer.meshes = modelProps.meshes;


    //  var new_zip = new JSZip();
    //  new_zip.loadAsync(urls.zip).then(async function(zipped) {
    //     var jsonFile = await zipped.file("theJsonFile.jsson").async("text");
    // })

  }
  generateUVs(geometry) {
    const position = geometry.attributes.position;
    const uvs = new Float32Array(position.count * 2);

    for (let i = 0; i < position.count; i++) {
      // Compute UVs based on vertex positions
      const x = position.getX(i);
      const y = position.getY(i);
      uvs[i * 2] = (x + 1) / 2; // Normalize x to range [0, 1]
      uvs[i * 2 + 1] = (y + 1) / 2; // Normalize y to range [0, 1]
    }

    geometry.setAttribute('uv', new BufferAttribute(uvs, 2));
  }

  SimplifyMesh(geometry, targetVertexCount) {
    // Check if geometry is properly defined
    if (!geometry
      || !geometry.attributes
      || !geometry.attributes.position) {
      console.error('Geometry is not properly defined.');
      return null;
    }

    if (isNaN(targetVertexCount) ||
      targetVertexCount <= 0) {
      console.error('Target vertex count is invalid:', targetVertexCount);
      return null;
    }

    try {
      return modify(geometry, targetVertexCount);
    } catch (error) {
      console.error('Error applying simplification:', error);
      return null;
    }
    return null;
  }

  SetMetaData(mesh, matrix, color, ind, uniqueId) {
  //  mesh.instanceMatrix.setUsage(DynamicDrawUsage);
    mesh.matrixAutoUpdate = false;
    mesh.updateMatrix();
    mesh.setMatrixAt(ind, matrix)
    mesh.setColorAt(ind, color);
    mesh.index = ind;
    mesh.needsUpdate = true;
    mesh.matrix.makeRotationAxis(new Vector3(1, 0, 0), -Math.PI / 2)
    mesh.matrixWorldNeedsUpdate = true;
    mesh.instanceMatrix.needsUpdate = true;
    mesh.instanceColor.needsUpdate = true;
    //  mesh.castShadow = true;
    //  mesh.receiveShadow = true;
    mesh.name = uniqueId;
    mesh.visible = true;
    mesh.frustumCulled = true;
    // if (mesh.isMesh) {
    //   mesh.material.transparent = true;
    // }

  }

 

  async loadModel(modelProps, glbmodel, viewer, reactapi, isIfcProject) {
    const gltfLoader = new GLTFLoader();


    await gltfLoader.load(
      glbmodel,
      gltf => {
        var model = gltf.scene;
        var finalModel = new Scene();

        if (modelProps.created) {

          model.position.set(modelProps.position.x, modelProps.position.y, modelProps.position.z);
          model.rotation.set(modelProps.rotation.x, modelProps.rotation.y, modelProps.rotation.z);
          model.scale.set(modelProps.scale.x, modelProps.scale.y, modelProps.scale.z)


        } else if (!modelProps.created && model.children?.length > 0) {


          // console.log("isIFCProject from loader", isIfcProject)
          model.position.set(0, 0, 0);
          if (isIfcProject) {

            model.rotation.x = Math.PI;
            model.rotation.z = Math.PI;
            model.rotation.y = Math.PI;
          } else {
            model.rotation.x = -Math.PI / 2;
          }

          const temp = new Box3().setFromObject(model).getSize(new Vector3());
          let size = temp.toArray();
          let max = 100.0 / Math.max(...size);
          model.scale.set(max, max, max)
          this.viewer.scene.modelScale = max;

          viewer.camera.position.set(0, 130, 250);

          viewer.cameraControls.setPosition(0, 130, 250);

          viewer.cameraControls.setTarget(0, 0, 0);



          modelProps.position.set(0, 0, 0);
          modelProps.rotation.x = -Math.PI / 2;
          modelProps.scale.set(max, max, max);
          modelProps.created = true;

        }
        const color = new Color();
        const solidMat = new MeshBasicMaterial({
          vertexColors: true,
          side: DoubleSide,
          wireframe: false,
           vertexAlphas: true,
           transparent: false,
          // depthWrite: false,
          // depthTest: true,
          // flatShading: true,
        })

        const transParentMat = new MeshBasicMaterial({
          vertexColors: true,
          side: DoubleSide,
          wireframe: false,
          transparent: true,
           vertexAlphas: true,
          depthWrite: true,
          //  depthTest: true,
          // flatShading: true,
        })
        let mat = solidMat;
        let index = 0;

        model.traverse(item => {

          if (item.isMesh) {

            const data = Object.assign({}, item.userData);
            const length = Object.keys(data).length;
            let ind = 0;
            index++;

            // geo.computeVertexNormals();
            // if (length > 1) {
            // if (false) {
            if (true) {

              const geo = item.geometry;
         
              console.log("transparent = " + data[0].transParent);

              // const val = this.isTransparent(colors);
              // console.log(val);

              if (data[0].transParent) {
                mat = transParentMat;
              }
              else {
                mat = solidMat;
              }

              const mesh = new InstancedMesh(geo, mat, length)
              //  const mediumMesh = new InstancedMesh(geomMedium, mat, length)
              //  const lowMesh = new InstancedMesh(geomLow, mat, length)
              // mesh.material.transparent = true;
              // mesh.material.alphaTest = 0.5;

              let userData = [];
              for (var obj in { ...data }) {

                userData.push(data[obj]);
                let transformation = data[obj].transformation;
                let uniqueId = data[obj].uniqueId;


                const matrix = new Matrix4()
                matrix.set(
                  transformation.m0, transformation.m1, transformation.m2, transformation.m3,
                  transformation.m4, transformation.m5, transformation.m6, transformation.m7,
                  transformation.m8, transformation.m9, transformation.m10, transformation.m11,
                  transformation.m12, transformation.m13, transformation.m14, transformation.m15)

                this.SetMetaData(mesh, matrix, color, ind, uniqueId);
                let indMesh = this.GetIndividualBox(geo, mat, matrix)


                //   this.SetMetaData(mediumMesh, matrix, color, ind, uniqueId);
                //   this.SetMetaData(lowMesh, matrix, color, ind, uniqueId);

                ind++;

                this.viewer.uniqueMeshes.set(uniqueId, indMesh);
              }

              // let box3 = new Box3()
              // box3.expandByObject(mesh.mesh);
              // const size = new Vector3();
              // box3.getSize(size);

              // // Extract dimensions
              // const length = size.x; // width
              // const height = size.y; // height
              // const breadth = size.z; // depth

              //   const lod = new LOD()
              //   lod.addLevel(lowMesh, 2000)
              //   lod.addLevel(mediumMesh, 1000)
              //  lod.addLevel(mesh, 0)
              finalModel.add(mesh);

              mesh.userData = userData;
              //   finalModel.add(mesh);
              SetChildrenDepthWrite(mesh);

              modelProps.meshes.push(mesh);

            }
            else {
              let userData = [];

              item.matrixAutoUpdate = false;
              let transformation = item.userData[0].transformation;
              userData = item.userData;

              const matrix = new Matrix4()
              matrix.set(
                transformation.m0, transformation.m1, transformation.m2, transformation.m3,
                transformation.m4, transformation.m5, transformation.m6, transformation.m7,
                transformation.m8, transformation.m9, transformation.m10, transformation.m11,
                transformation.m12, transformation.m13, transformation.m14, transformation.m15)


              const geo = item.geometry.clone();

              const mesh = new Mesh(geo, mat, 1)
              // mesh.instanceMatrix.setUsage(DynamicDrawUsage);

              mesh.matrixAutoUpdate = false;

              mesh.updateMatrix();
              mesh.applyMatrix4(matrix)
              mesh.needsUpdate = true;


              mesh.matrix.makeRotationAxis(new Vector3(1, 0, 0), -Math.PI / 2)
              mesh.matrixWorldNeedsUpdate = true;
              //    mesh.instanceMatrix.needsUpdate = true;

              mesh.userData = userData;
              // console.log("mesh added to final model", item.userData.length)

              finalModel.add(mesh);
              modelProps.meshes.push(mesh);

              mesh.material.transparent = true;
            }
          }
        });

        let SingleInstance = 0;
        let Type = 0;

        modelProps.meshes.forEach(mesh => {

          let i = 0;
          Type++;
          mesh.userData.forEach(ud => {
            let meshData = {
              mesh: mesh,
              uniqueId: ud.uniqueId,
              index: i
            }

            modelProps.mapMeshes.set(ud.uniqueId, meshData);
            i++;
          });

          if (i == 1) {
            SingleInstance++;
          }

        });

        console.log("Type", Type);
        console.log("SingleInstance", SingleInstance);
        console.log("Complete", modelProps.mapMeshes.size);

        finalModel.updateMatrixWorld(true);
        finalModel.frustumCulled = true;
        finalModel.needsUpdate = true;
        viewer.scene.add(finalModel);
        viewer.loadedModels.push(finalModel);
        viewer.animate();

        reactapi.setLoading(false);
        reactapi.setAllowToResize(true);
      },
      progressEvent => {
        reactapi.setPercentsLoaded(
          Math.floor(((progressEvent.loaded * 100) / (progressEvent.total / this.totalglbs))),
        );
      },

      () => reactapi.setError(true),
    );

  }
  GetIndividualBox(geo, mat, instanceMatrix) {
    const mesh = new InstancedMesh(geo, mat, 1)
    mesh.instanceMatrix.setUsage(DynamicDrawUsage);

    mesh.matrixAutoUpdate = false;

    mesh.updateMatrix();
    mesh.setMatrixAt(0, instanceMatrix)
    mesh.needsUpdate = true;

    mesh.matrix.makeRotationAxis(new Vector3(1, 0, 0), -Math.PI / 2)
    mesh.matrixWorldNeedsUpdate = true;
    mesh.instanceMatrix.needsUpdate = true;

    return mesh;
  }
}
